import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { graphql, Link as GatsbyLink } from "gatsby"
import { Link as ChakraLink, Flex } from "@chakra-ui/core"
import Img from "gatsby-image"

export const query = graphql`
  query IndexTemplateQuery($id: Int) {
    allStrapiWork {
      edges {
        node {
          id
          on_homepage
          published
          slug
          title
          cover_image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    strapiTag(strapiId: { eq: $id }) {
      works {
        id
        published
        title
        slug
        cover_image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default ({ data }) => {
  // console.log({ data })
  const isSSR = typeof window === "undefined"
  useEffect(() => {
    console.log(location.pathname)
  }, [])
  // const [currentHover, setCurrentHover] = React.useState(null)

  // useEffect(() => {
  //   setTopLeft()
  // }, [])

  // const [topLeft, setTopLeft] = useState({
  //   top: () => `${Math.round(Math.random() * 100)}%`,
  //   left: () => `${Math.round(Math.random() * 100)}%`,
  // })
  useEffect(() => {
    // if (window) {
    // console.log('window')
    if (window.innerWidth > 400) {
      // console.log(window.location.pathname)
      // if (window.location.pathname === '/') {
      setTimeout(() => {
        const homePageImgs = document.querySelectorAll(".home-page-imgs")

        homePageImgs.forEach(img => {
          img.addEventListener("mouseenter", function (event) {
            // console.log(event, event.target.className)
            event.target.className = `home-page-imgs gatsby-image-wrapper hovered`
            homePageImgs.forEach(img => {
              img.style.filter = "grayscale(100%)"
            })
          })
        })

        homePageImgs.forEach(img => {
          img.addEventListener("mouseleave", function (event) {
            // console.log(event, event.target.className)
            event.target.className = `home-page-imgs gatsby-image-wrapper`
            homePageImgs.forEach(img => {
              img.style.filter = "blur(0rem) opacity(100%)"
            })
          })
        })
      }, 100)
      // }
    }
    // }
  }, [])
  return (
    <Layout>
      <div
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
        }}
      >
        <div
          className="imgs-wrapper-main"
          style={{
            position: "relative",
            width: "60%",
            height: "60%",
            // display: "flex",
            // flexDirection: "column",
          }}
        >
          {/* this div allow correct element positioning */}
          {/* <div style={{ height: "100vh", width: "100vw" }}></div> */}

          {/* mobile version */}
          {!isSSR && location.pathname === "/" ? (
            <>
              <div
                className="mobile-wrapper mobile is-not-SSR-home-page1"
                style={{
                  top: 0,
                  left: 0,
                  position: "fixed",
                  height: "100vh",
                  width: "100vw",
                  overflow: "scroll",
                  // display: "flex", // declared in CSS
                  flexDirection: "column",
                  gap: 30,
                  padding: "7rem 1.2rem 3rem 1.2rem",
                }}
              >
                {data.allStrapiWork.edges
                  .filter(x => x.node.published)
                  .filter(x => x.node.on_homepage)
                  .map(({ node }) => {
                    console.log(node, "page1")
                    return (
                      <ChakraLink
                        key={`nav-link-mobile-${node.id}`}
                        className="nav-link mobile"
                        style={
                          {
                            // paddingRight: 15,
                            // paddingLeft: 30,
                            // paddingBottom: 20,
                          }
                        }
                        // @ts-ignore
                        as={GatsbyLink}
                        to={`/${node.slug}`}
                      >
                        <Img
                          className="mobile"
                          fluid={node?.cover_image?.childImageSharp?.fluid}
                          style={{
                            width: "100%",
                          }}
                        />
                        {node.title}
                      </ChakraLink>
                    )
                  })}
              </div>
            </>
          ) : (
            <>
              <div
                className="mobile-wrapper mobile is-not-SSR-home-page2"
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100vw",
                  height: "100vh",
                  overflow: "scroll",
                  // display: "flex", // declared in CSS
                  flexDirection: "column",
                  gap: 30,
                  margin: 0,
                  // padding: 0,
                  // paddingTop: 40,
                  padding: "7rem 1.2rem 3rem 1.2rem",
                }}
              >
                {data.strapiTag.works
                  .filter(work => work.published)
                  .map(work => {
                    console.log(work, "page2")
                    return (
                      <ChakraLink
                        key={`tag-work-${work.id}`}
                        className="nav-link mobile"
                        style={
                          {
                            // paddingRight: 15,
                            // paddingLeft: 30,
                            // paddingBottom: 20,
                          }
                        }
                        // @ts-ignore
                        as={GatsbyLink}
                        to={`/${work.slug}`}
                      >
                        <Img
                          className="mobile"
                          fluid={work?.cover_image?.childImageSharp?.fluid}
                          style={{
                            width: "100%",
                          }}
                        />

                        {work.title}
                      </ChakraLink>
                    )
                  })}
              </div>
            </>
          )}
          {/* if not SSR render page for home page or for tags */}
          {!isSSR &&
          location.pathname === "/" &&
          globalThis.innerWidth > 900 ? (
            data.allStrapiWork.edges
              .filter(x => x.node.published)
              .filter(x => x.node.on_homepage)
              .map(({ node }) => {
                console.log({ node }, "page3")
                return (
                  <div
                    key={`index-work-img-${node.id}`}
                    className="main-home-page is-not-SSR-home-page3"
                    style={{ width: "100vw", height: "100vh" }}
                  >
                    {/* CARD */}
                    <ChakraLink
                      onMouseOver={() => {
                        // console.log(node.id)
                      }}
                      onMouseLeave={() => {}}
                      style={{
                        paddingRight: 15,
                        paddingLeft: 30,
                      }}
                      // @ts-ignore
                      as={GatsbyLink}
                      to={`/${node.slug}`}
                    >
                      <Img
                        className="home-page-imgs"
                        // id={`card-image-${node.id}`}
                        fluid={node?.cover_image?.childImageSharp?.fluid}
                        style={{
                          width:
                            node?.cover_image?.childImageSharp?.fluid
                              ?.aspectRatio > 1
                              ? "305px"
                              : "195px",
                          // height:
                          //   node.cover_image.childImageSharp.fluid.aspectRatio < 1
                          //     ? "300px"
                          //     : "100px",
                          margin: "1rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          top: `${Math.round(Math.random() * 100 + 10)}%`,
                          left: `${Math.round(Math.random() * 100) + 10}%`,
                          // filter: `grayscale(${gray ? "100" : "0"}%)`,
                          // top: topLeft.top(),
                          // left: topLeft.left(),
                          // top: top,
                          // left: left,
                          // top: 300,
                          // left: 300,
                        }}
                        imgStyle={
                          {
                            // objectFit: "contain",
                          }
                        }
                      />
                    </ChakraLink>
                  </div>
                )
              })
          ) : (
            <div className="secondary-home-page is-not-SSR-home-page4">
              {data.strapiTag.works
                .filter(work => work.published)
                .map(work => {
                  console.log(work, "page4")
                  return (
                    <div
                      key={`index-work-img-tag-${work.id}`}
                      className="main-home-page"
                    >
                      <ChakraLink
                        style={{
                          paddingRight: 15,
                          paddingLeft: 30,
                        }}
                        // @ts-ignore
                        as={GatsbyLink}
                        to={`/${work.slug}`}
                      >
                        <Img
                          className="home-page-imgs"
                          fluid={work?.cover_image?.childImageSharp?.fluid}
                          style={{
                            width: "300px",
                            height: "300px",
                            margin: "1rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            top: `${Math.round(Math.random() * 100)}%`,
                            left: `${Math.round(Math.random() * 100)}%`,
                          }}
                          imgStyle={{
                            objectFit: "contain",
                          }}
                        />
                      </ChakraLink>
                    </div>
                  )
                })}
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}
